<template scoped>
  <div class="designtool-connect">
    <div>
      <el-card
        class="box-card"
        :style="{ background: '#9AD1D4', color: '#fff' }"
      >
        <div slot="header" class="clearfix">
          <span>Connect</span>
        </div>

        <div class="text item">
          {{ description }}
        </div>
      </el-card>
      <DesignToolLogic-Edit
        :id="id"
        :editLogic="editLogic"
      ></DesignToolLogic-Edit>
      <DesignToolLogic-Delete
        :id="id"
        :deleteLogic="deleteLogic"
      ></DesignToolLogic-Delete>
    </div>
    <DesignToolLogic-Arrow-Bottom> </DesignToolLogic-Arrow-Bottom>
  </div>
</template>

<script scoped>
export default {
  props: {
    id: String,
    description: String,
    deleteLogic: Function,
    editLogic: Function,
  },
  components: {
    "DesignToolLogic-Arrow-Bottom": () =>
      import(
        "./Arrow-Bottom.vue"
      ),
    "DesignToolLogic-Delete": () =>
      import(
        "./Delete.vue"
      ),
    "DesignToolLogic-Edit": () =>
      import(
        "./Edit.vue"
      ),
  },
};
</script>

<style scoped>
.designtool\-connect {
  width: 300px;
  text-align: center;
  position: relative;
}
</style>

